import React from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import scrollPointer from "../assets/images/down-arrow.svg";
import {
  getRightContent,
  getVisibleSensorAnimation,
  setAdditionalClasses,
} from "../assets/js/utils";
import content from "../assets/content/content.json";
import ReadMore from "./ReadMore";
import {
  toggleReadMore,
  toggleSectionContentDisplay,
} from "../store/auxiliary/ui/sections";
import useWindowSize from "./hooks/useWindowSize";

function SectionDescriptor({
  topSubTitle,
  title = [],
  text,
  image,
  displayScrollIndicator,
  displayReadMore,
  handleReadMorePressed,
  whiteBackgroundColor,
  pearson,
  largerImage,
  applyTextClassname,
}) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const windowWidth = useWindowSize();
  // const sections = useSelector((state) => state.ui.sections);
  // const dispatch = useDispatch();

  // const handleReadMorePressed = () => {
  //   dispatch(toggleReadMore());
  //   setTimeout(() => {
  //     dispatch(toggleSectionContentDisplay(true));
  //   }, 500);

  //   setTimeout(() => {
  //     dispatch(toggleReadMore());
  //   }, 1000);
  // };

  return (
    <div>
      <div
        className={`section-descriptor${setAdditionalClasses(
          ["white-background"],
          [whiteBackgroundColor]
        )}`}
      >
        <div
          className={`section-descriptor__first-part${setAdditionalClasses(
            ["white-background"],
            [whiteBackgroundColor]
          )}`}
        >
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <div
                style={getVisibleSensorAnimation(
                  isVisible,
                  `fade-in-20vh 1.2s 0.5s backwards`
                )}
              >
                {topSubTitle && (
                  <h2
                    className={`section-descriptor__top-subtitle${setAdditionalClasses(
                      ["white-background"],
                      [whiteBackgroundColor]
                    )}`}
                  >
                    {topSubTitle}
                  </h2>
                )}
                <h1
                  className={`section-descriptor__title${setAdditionalClasses(
                    ["white-background"],
                    [whiteBackgroundColor]
                  )}`}
                >
                  {title.map((titleLine, index) => (
                    <span key={index} className="block">
                      {titleLine}
                    </span>
                  ))}
                </h1>
                {text && (
                  <p
                    className={`section-descriptor__text${setAdditionalClasses(
                      [applyTextClassname],
                      [applyTextClassname]
                    )}`}
                  >
                    {text}
                  </p>
                )}
                {displayReadMore && (
                  <ReadMore
                    onPress={handleReadMorePressed}
                    whiteBackgroundColor={whiteBackgroundColor}
                  />
                )}
              </div>
            )}
          </VisibilitySensor>
        </div>
        <div
          className={`section-descriptor__second-part${setAdditionalClasses(
            ["white-background", "move-top"],
            [whiteBackgroundColor, largerImage]
          )}`}
        >
          <div>
            <VisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <>
                  <img
                    src={image}
                    alt=""
                    className={`section-descriptor__image`}
                    style={getVisibleSensorAnimation(
                      isVisible,
                      `fade-in-20vh 2s ${
                        windowWidth < 1024 ? 0.5 : 0.8
                      }s backwards`
                    )}
                  />
                  {pearson && (
                    <div className="section-descriptor__image-details">
                      <div
                        className="section-descriptor__image-details-name"
                        style={getVisibleSensorAnimation(
                          isVisible,
                          `fade-in-scale 1.5s 1.5s backwards`
                        )}
                      >
                        {pearson.name}
                      </div>
                      <div
                        className="section-descriptor__image-details-position"
                        style={getVisibleSensorAnimation(
                          isVisible,
                          `fade-in-20vh 1.5s 1.5s backwards`
                        )}
                      >
                        {pearson.position}
                      </div>
                    </div>
                  )}
                </>
              )}
            </VisibilitySensor>
          </div>
        </div>
      </div>
      <div
        className={
          displayScrollIndicator ? "scroll-pointer__container" : "hide"
        }
      >
        <span className="scroll-pointer__label">
          {getRightContent(
            generalSettings.rtlLanguage,
            content.generalTerms.scroll
          )}
        </span>
        <div className="scroll-pointer">
          <img src={scrollPointer} alt="" />
        </div>
      </div>
    </div>
  );
}

export default SectionDescriptor;
