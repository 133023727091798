import React from "react";
import constants from "../assets/js/constants";
import VisibilitySensor from "react-visibility-sensor";
import {
  getDelayedIndexBasedAnimationTime,
  getVisibleSensorAnimation,
} from "../assets/js/utils";
import useWindowSize from "./hooks/useWindowSize";

function Note({
  title,
  components = [],
  background = `radial-gradient(${constants.COLORS.PRIMARY_LIGHT}, ${constants.COLORS.PRIMARY})`,
}) {
  const windowWidth = useWindowSize();
  return (
    <div className="note" style={{ background }}>
      <h2 className="note__title">{title}</h2>
      <div className="note__components">
        {components.map((component, index) => (
          <VisibilitySensor partialVisibility key={index}>
            {({ isVisible }) => (
              <div
                className="note__item-component"
                style={getVisibleSensorAnimation(
                  isVisible,
                  `fade-in-40px 1s ${getDelayedIndexBasedAnimationTime(
                    windowWidth,
                    index
                  )}s backwards`
                )}
              >
                {component}
              </div>
            )}
          </VisibilitySensor>
        ))}
      </div>
    </div>
  );
}

export default Note;
