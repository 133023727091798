import React from "react";
import { useSelector } from "react-redux";
import { whiteBackgroundsMainSection } from "../assets/js/images";
import { setAdditionalClasses } from "../assets/js/utils";
import useResponsiveImage from "./hooks/useResponsiveImage";
// import ResponsiveImage from "./ResponsiveImage";

function MainSection({ images, sectionId, SectionContent, avoidOverlay }) {
  const sections = useSelector((state) => state.ui.sections);
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  return (
    <div
      id={sectionId}
      className="main-section"
      style={{ backgroundImage: `url(${useResponsiveImage(images)}` }}
    >
      {/* <div className="main-section__background-image">
        <ResponsiveImage images={images} />
      </div> */}
      <div
        className={`content-area__main-content${setAdditionalClasses(
          [
            "animate-overlay-background",
            "animate-overlay-background-flipped",
            "white-background",
          ],
          [
            !sections.sectionAlreadyChanged && !avoidOverlay,
            sections.sectionAlreadyChanged && !avoidOverlay,
            (whiteBackgroundsMainSection(sections.currentSection) ||
              [2, 6].includes(sections.currentSection)) &&
              generalSettings.rtlLanguage,
          ]
        )}`}
      >
        {SectionContent}
      </div>
    </div>
  );
}

export default MainSection;
