import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import { Element, scroller } from "react-scroll";

import { getPreloadedImage } from "../assets/js/images";
import {
  getRightContent,
  getVisibleSensorAnimation,
  setAdditionalClasses,
} from "../assets/js/utils";
import {
  changeSection,
  sectionAlreadyChangedDeactivate,
  toggleReadMore,
  toggleReadMoreClosed,
  toggleSectionContentDisplay,
} from "../store/auxiliary/ui/sections";
import ContentViewer from "./ContentViewer";
import MainSection from "./MainSection";
import Menu from "./Menu";
import SectionDescriptor from "./SectionDescriptor";
import SectionIndicator from "./SectionIndicator";
import useWindowSize from "./hooks/useWindowSize";

import content from "../assets/content/content.json";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";
import Content6 from "./Content6";
import Content7 from "./Content7";

import chairmanBackground from "../assets/images/chairman-background.png";
import ceoBackground from "../assets/images/ceo-background.png";
import chairmanImage from "../assets/images/chairman.jpg";
import ceoImage from "../assets/images/ceo.png";
import cfoImage from "../assets/images/cfo-4.png";
import closeIcon from "../assets/images/close_icon.svg";
import grayBackground from "../assets/images/gray-background-2.png";
import useSectionsHandler from "./hooks/useSectionsHandler";

function ContentArea(props) {
  const sections = useSelector((state) => state.ui.sections);
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const dispatch = useDispatch();
  const currentSectionContent = content.sections;
  const windowWidth = useWindowSize();

  useEffect(() => {}, []);

  const handleScroller = (sectionNumber) => {
    console.log("helllo", sectionNumber);
  };

  useSectionsHandler(handleScroller);

  const handleReadMorePressed = () => {
    dispatch(toggleReadMore());
    setTimeout(() => {
      dispatch(toggleSectionContentDisplay(true));
    }, 500);

    setTimeout(() => {
      dispatch(toggleReadMore());
    }, 1000);
  };

  const handleReadMoreClosed = () => {
    dispatch(toggleReadMoreClosed());
    dispatch(changeSection(0));
    setTimeout(() => {
      dispatch(toggleSectionContentDisplay(false));
      dispatch(sectionAlreadyChangedDeactivate()); //call to avoid overlay then brightness then overlay
    }, 500);
    setTimeout(() => {
      dispatch(toggleReadMoreClosed());
    }, 1000);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className={`menu-open${setAdditionalClasses(
          ["active", "draw"],
          [
            generalSettings.menuOpen,
            !generalSettings.menuOpen && generalSettings.menuPressedAtLeastOnce,
          ]
        )}`}
      >
        <Menu
          menuItems={getRightContent(generalSettings.rtlLanguage, [
            [
              "2021 At A Glance",
              "Our Strategic Milestones",
              "Chairman's Message",
              "CEO’s Message",
              "CFO’s Review",
              "Our Business Model",
              "Sustainability",
            ],
            [
              "2021 At A Glance",
              "Our Strategic Milestones",
              "Chairman's Message",
              "CEO’s Message",
              "CFO’s Review",
              "Our Business Model",
              "Sustainability",
            ],
          ])}
        />
      </div>
      <div id="content-area" className="content-area">
        <div
          className={`section-indicator__wrapper${setAdditionalClasses(
            ["hide"],
            [sections.displayContent]
          )}`}
        >
          <SectionIndicator
            numberOfSections={sections.totalSections}
            currentSection={sections.currentSection}
            height={200}
          />
        </div>

        <div
          id="content-area__wrapper"
          className={`content-area__wrapper${setAdditionalClasses(
            ["display-content"],
            [sections.displayContent]
          )}`}
        >
          {!sections.displayContent && (
            <>
              {/* <div
                id="section-0"
                style={{
                  backgroundColor: "orange",
                }}
                className="content-area__main-content"
              > */}
              <Element name="scroll-section-0">
                <MainSection
                  images={getPreloadedImage(0)}
                  sectionId={`section-0`}
                  SectionContent={
                    <SectionDescriptor
                      displayScrollIndicator={true}
                      topSubTitle={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[0].topSubtitle
                      )}
                      title={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[0].title
                      )}
                      currentSection={sections.currentSection}
                    />
                  }
                />
              </Element>
              <Element name="scroll-section-1">
                <MainSection
                  images={getPreloadedImage(1)}
                  sectionId={`section-1`}
                  SectionContent={
                    <SectionDescriptor
                      displayReadMore={true}
                      topSubTitle={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[1].topSubtitle
                      )}
                      title={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[1].title
                      )}
                      handleReadMorePressed={handleReadMorePressed}
                    />
                  }
                />
              </Element>
              <Element name="scroll-section-2">
                <MainSection
                  images={getPreloadedImage(2)}
                  sectionId={`section-2`}
                  SectionContent={
                    <SectionDescriptor
                      displayReadMore={true}
                      topSubTitle={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[2].topSubtitle
                      )}
                      title={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[2].title
                      )}
                      handleReadMorePressed={handleReadMorePressed}
                    />
                  }
                />
              </Element>
              <Element name="scroll-section-3">
                <MainSection
                  images={getPreloadedImage(3)}
                  sectionId={`section-3`}
                  avoidOverlay={true}
                  SectionContent={
                    <>
                      <VisibilitySensor partialVisibility>
                        {({ isVisible }) => (
                          <div
                            className="main-section__moving-background"
                            style={getVisibleSensorAnimation(
                              isVisible,
                              `${
                                windowWidth < 1024
                                  ? "moving-background-small-screen"
                                  : "moving-background"
                              } 2.8s 0.6s backwards ease-out`
                            )}
                          >
                            <img
                              src={chairmanBackground}
                              alt=""
                              className="main-section__moving-background-image"
                            />
                          </div>
                        )}
                      </VisibilitySensor>
                      <SectionDescriptor
                        pearson={{
                          name: getRightContent(
                            generalSettings.rtlLanguage,
                            currentSectionContent[3].pearsonImage.name
                          ),
                          position: getRightContent(
                            generalSettings.rtlLanguage,
                            currentSectionContent[3].pearsonImage.position
                          ),
                        }}
                        whiteBackgroundColor={true}
                        displayReadMore={true}
                        topSubTitle={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[3].topSubtitle
                        )}
                        title={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[3].title
                        )}
                        handleReadMorePressed={handleReadMorePressed}
                        text={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[3].text
                        )}
                        image={chairmanImage}
                      />
                    </>
                  }
                />
              </Element>
              <Element name="scroll-section-4">
                <MainSection
                  images={getPreloadedImage(4)}
                  sectionId={`section-4`}
                  avoidOverlay={true}
                  SectionContent={
                    <>
                      <VisibilitySensor partialVisibility>
                        {({ isVisible }) => (
                          <>
                            <div
                              className="main-section__moving-background"
                              style={getVisibleSensorAnimation(
                                isVisible,
                                `${
                                  windowWidth < 1024
                                    ? "moving-background-small-screen"
                                    : "moving-background"
                                } 2.8s 0.6s backwards ease-out`
                              )}
                            >
                              <img
                                src={chairmanBackground}
                                alt=""
                                className="main-section__moving-background-image"
                              />
                            </div>
                            <div
                              className="main-section__moving-background small-width"
                              style={getVisibleSensorAnimation(
                                isVisible,
                                `${
                                  windowWidth < 1024
                                    ? "moving-background-small-screen"
                                    : "moving-background"
                                } 2.2s 1s backwards ease-in-out`
                              )}
                            >
                              <img
                                src={ceoBackground}
                                alt=""
                                className="main-section__moving-background-image"
                              />
                            </div>
                          </>
                        )}
                      </VisibilitySensor>

                      <SectionDescriptor
                        largerImage={true}
                        pearson={{
                          name: getRightContent(
                            generalSettings.rtlLanguage,
                            currentSectionContent[4].pearsonImage.name
                          ),
                          position: getRightContent(
                            generalSettings.rtlLanguage,
                            currentSectionContent[4].pearsonImage.position
                          ),
                        }}
                        whiteBackgroundColor={true}
                        displayReadMore={true}
                        topSubTitle={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[4].topSubtitle
                        )}
                        title={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[4].title
                        )}
                        handleReadMorePressed={handleReadMorePressed}
                        text={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[4].text
                        )}
                        image={ceoImage}
                      />
                    </>
                  }
                />
              </Element>
              <Element name="scroll-section-5">
                <MainSection
                  images={getPreloadedImage(5)}
                  sectionId={`section-5`}
                  avoidOverlay={true}
                  SectionContent={
                    <>
                      <VisibilitySensor partialVisibility>
                        {({ isVisible }) => (
                          <>
                            <div
                              className="main-section__moving-background"
                              style={getVisibleSensorAnimation(
                                isVisible,
                                `${
                                  windowWidth < 1024
                                    ? "moving-background-small-screen"
                                    : "moving-background"
                                } 2.8s 0.6s backwards ease-out`
                              )}
                            >
                              <img
                                src={chairmanBackground}
                                alt=""
                                className="main-section__moving-background-image"
                              />
                            </div>
                            <div
                              className="main-section__moving-background small-width"
                              style={getVisibleSensorAnimation(
                                isVisible,
                                `${
                                  windowWidth < 1024
                                    ? "moving-background-small-screen"
                                    : "moving-background"
                                } 2.2s 1s backwards ease-in-out`
                              )}
                            >
                              <img
                                src={grayBackground}
                                alt=""
                                className="main-section__moving-background-image"
                              />
                            </div>
                          </>
                        )}
                      </VisibilitySensor>

                      <SectionDescriptor
                        largerImage={true}
                        pearson={{
                          name: getRightContent(
                            generalSettings.rtlLanguage,
                            currentSectionContent[5].pearsonImage.name
                          ),
                          position: getRightContent(
                            generalSettings.rtlLanguage,
                            currentSectionContent[5].pearsonImage.position
                          ),
                        }}
                        whiteBackgroundColor={true}
                        displayReadMore={true}
                        topSubTitle={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[5].topSubtitle
                        )}
                        title={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[5].title
                        )}
                        handleReadMorePressed={handleReadMorePressed}
                        text={getRightContent(
                          generalSettings.rtlLanguage,
                          currentSectionContent[5].text
                        )}
                        image={cfoImage}
                      />
                    </>
                  }
                />
              </Element>
              <Element name="scroll-section-6">
                <MainSection
                  images={getPreloadedImage(6)}
                  sectionId={`section-6`}
                  SectionContent={
                    <SectionDescriptor
                      displayReadMore={true}
                      topSubTitle={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[6].topSubtitle
                      )}
                      title={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[6].title
                      )}
                      text={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[6].text
                      )}
                      handleReadMorePressed={handleReadMorePressed}
                      applyTextClassname="color-white"
                    />
                  }
                />
              </Element>
              <Element name="scroll-section-7">
                <MainSection
                  images={getPreloadedImage(7)}
                  sectionId={`section-7`}
                  SectionContent={
                    <SectionDescriptor
                      displayReadMore={true}
                      topSubTitle={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[7].topSubtitle
                      )}
                      title={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[7].title
                      )}
                      text={getRightContent(
                        generalSettings.rtlLanguage,
                        currentSectionContent[7].text
                      )}
                      handleReadMorePressed={handleReadMorePressed}
                      applyTextClassname="color-white"
                    />
                  }
                />
              </Element>
            </>
          )}

          {sections.displayContent && (
            <div style={{ height: "100%", width: "100%" }}>
              <ContentViewer
                Content={
                  <div>
                    <img
                      src={closeIcon}
                      alt=""
                      className={`close-icon${setAdditionalClasses(
                        ["hide"],
                        [generalSettings.menuOpen]
                      )}`}
                      onClick={handleReadMoreClosed}
                    />
                    {[
                      null,
                      Content1,
                      Content2,
                      Content3,
                      Content4,
                      Content5,
                      Content6,
                      Content7,
                    ].map(
                      (Content, index) =>
                        sections.currentSection === index &&
                        index !== 0 &&
                        sections.displayContent && <Content key={index} />
                    )}
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContentArea;
