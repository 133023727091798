import React, { useEffect, useState } from "react";
import constants from "../assets/js/constants";
import { setAdditionalClasses } from "../assets/js/utils";

const circleConfig = {
  viewBox: "0 0 38 38",
  x: "19",
  y: "19",
  radio: "15.91549430918954",
};

function ProgressBar({
  trailStrokeColor = constants.COLORS.PRIMARY_LIGHTER,
  strokeColor = constants.COLORS.PRIMARY,
  percentage,
  speed = 20,
  delay = 0,
  isVisible,
  label,
  applyClassNameLabel,
}) {
  const [progressBar, setProgressBar] = useState(0);
  const [canStart, setCanStart] = useState(false);
  const pace = percentage / speed;
  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, pace);
  };

  useEffect(() => {
    if (isVisible)
      setTimeout(() => {
        setCanStart(true);
      }, delay);
    setProgressBar(0);
    setCanStart(false);
  }, [isVisible]);

  useEffect(() => {
    if (percentage > 0 && canStart) {
      updatePercentage();
      setProgressBar(0);
    }
  }, [percentage, canStart, isVisible]);

  useEffect(() => {
    if (progressBar < percentage && canStart) updatePercentage();
    else if (progressBar > percentage && canStart) setProgressBar(percentage);
    else if (!canStart) {
      setProgressBar(0);
    }
  }, [progressBar, canStart]);
  return (
    <div className="progress-bar__container">
      <figure style={{ width: "100%", margin: "auto" }}>
        <svg viewBox={circleConfig.viewBox}>
          <circle
            className="ring"
            cx={circleConfig.x}
            cy={circleConfig.y}
            r={circleConfig.radio}
            fill="transparent"
            stroke={trailStrokeColor}
            strokeWidth={6}
          />

          <circle
            className="path"
            cx={circleConfig.x}
            cy={circleConfig.y}
            r={circleConfig.radio}
            fill="transparent"
            stroke={strokeColor}
            strokeDasharray={`${progressBar} ${100 - progressBar}`}
            strokeDashoffset={25}
            strokeWidth={5}
          />
          <g className="circle-label">
            <text x="50%" y="50%" className="circle-percentage">
              {progressBar}%
            </text>
          </g>
        </svg>
      </figure>
      <p
        className={`progress-bar__label${setAdditionalClasses(
          [applyClassNameLabel],
          [applyClassNameLabel]
        )}`}
        style={{ color: strokeColor }}
      >
        {label}
      </p>
    </div>
  );
}

export default ProgressBar;
