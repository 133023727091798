import React from "react";

function IconDescriptorHorizontal({ icon, text }) {
  return (
    <div className="icon-descriptor-horizontal">
      <img src={icon} alt="" className="icon-descriptor-horizontal__icon" />
      <div className="icon-descriptor-horizontal__text">{text}</div>
    </div>
  );
}

export default IconDescriptorHorizontal;
