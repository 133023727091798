import { createSlice } from "@reduxjs/toolkit";
import constants from "../../../assets/js/constants";

const slice = createSlice({
  name: `${constants.STATE_MANAGEMENT.AUXILIARY}/ui/generalSettings`,
  initialState: {
    rtlLanguage: false,
    menuOpen: false,
    menuPressedAtLeastOnce: false,
  },
  reducers: {
    languageToggled: (generalSettings, action) => {
      generalSettings.rtlLanguage = !generalSettings.rtlLanguage;
      generalSettings.menuPressedAtLeastOnce = false;
    },
    menuToggled: (generalSettings, action) => {
      generalSettings.menuOpen = !generalSettings.menuOpen;
      generalSettings.menuPressedAtLeastOnce = true;
    },
  },
});

export const toggleLanguage = () => (dispatch) => {
  dispatch(languageToggled());
};

export const toggleMenu = () => (dispatch) => {
  dispatch(menuToggled());
};

export const { languageToggled, menuToggled } = slice.actions;
export default slice.reducer;
