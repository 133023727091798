import React from "react";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import { getVisibleSensorAnimation } from "../assets/js/utils";

function ContentTitle({ label = [] }) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <div className="content-title">
          <div
            className="content-title__bar"
            style={getVisibleSensorAnimation(
              isVisible,
              `move-vertical 1.1s  backwards ease-in`
            )}
          ></div>

          <h1
            className="content-title__label"
            style={getVisibleSensorAnimation(
              isVisible,
              `${
                generalSettings.rtlLanguage
                  ? "move-margin-right"
                  : "move-margin-left"
              } 0.9s 0.3s backwards ease-out`
            )}
          >
            {label.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </h1>
        </div>
      )}
    </VisibilitySensor>
  );
}

export default ContentTitle;
