import React from "react";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import {
  getContent,
  getRightContent,
  getVisibleSensorAnimation,
} from "../assets/js/utils";
import CustomReadMore from "./CustomReadMore";
import StickyPerson from "./StickyPerson";

import chairmanCircleImage from "../assets/images/chairman-circle.png";
import ellipseSecondary from "../assets/images/ellipse-secondary.svg";

function Content3(props) {
  const sections = useSelector((state) => state.ui.sections);
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  return (
    <div>
      <div className="general-m">
        <StickyPerson
          FirstPart={
            <CustomReadMore
              text={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .chairmanAuthor.customReadMore1.text
              )}
              textComplement={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .chairmanAuthor.customReadMore1.complement
              )}
              applyMainTextClassNameInIndex={{
                classNames: ["color-primary", "", "color-secondary"],
                indexes: [0, 2],
              }}
            />
          }
          SecondPart={
            <VisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <div className="content-3__person-image-container">
                  <img
                    src={chairmanCircleImage}
                    alt="Tanmiah's chairman"
                    className="sticky-person__image"
                    style={getVisibleSensorAnimation(
                      isVisible,
                      "person-move 1s backwards ease-in"
                    )}
                  />
                  <img
                    src={ellipseSecondary}
                    alt=""
                    className="ellipse-secondary-image"
                    style={getVisibleSensorAnimation(
                      isVisible,
                      "ellipse-secondary-move 1s  ease-in backwards"
                    )}
                  />
                  <div className="section-descriptor__image-details">
                    <div
                      className="section-descriptor__image-details-name"
                      style={getVisibleSensorAnimation(
                        isVisible,
                        `fade-in-scale 1.5s 1s backwards`
                      )}
                    >
                      {getRightContent(
                        generalSettings.rtlLanguage,
                        getContent().sections[sections.currentSection].content
                          .chairmanAuthor.name
                      )}
                    </div>
                    <div
                      className="section-descriptor__image-details-position"
                      style={getVisibleSensorAnimation(
                        isVisible,
                        `fade-in-20vh 1.5s 1s backwards`
                      )}
                    >
                      {getRightContent(
                        generalSettings.rtlLanguage,
                        getContent().sections[sections.currentSection].content
                          .chairmanAuthor.job
                      )}
                    </div>
                  </div>
                </div>
              )}
            </VisibilitySensor>
          }
        />
      </div>
    </div>
  );
}

export default Content3;
