import React, { useEffect, useState } from "react";
import { Element, scroller, animateScroll } from "react-scroll";
import { useSelector } from "react-redux";
import { getContent, getRightContent } from "../assets/js/utils";
import ContentTitle from "./ContentTitle";
import MileStone from "./MileStone";

import upperIcon from "../assets/images/upper-icon.svg";

function Content2(props) {
  const [scrollOffset, setScrollOffset] = useState(0);
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const sections = useSelector((state) => state.ui.sections);

  // const handleLeftScroll = (offset) => {
  //   scroller.scrollTo("mile-stone__container", {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //     offset: offset,
  //     horizontal: true,
  //     containerId: "mile-stone__container",
  //   });
  // };

  console.log("scrollOffset", scrollOffset);
  useEffect(() => {
    if (scrollOffset > 0) {
      scroller.scrollTo(`mile-stone-${scrollOffset}`, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        ignoreCancelEvents: true,
        // offset: scrollOffset * 100,
        horizontal: true,
        containerId: "mile-stone__container",
      });
    }
  }, [scrollOffset]);

  const handleRightScroll = () => {};
  return (
    <div style={{ width: "100%" }}>
      <div className="general-m">
        <ContentTitle
          label={getRightContent(
            generalSettings.rtlLanguage,
            getContent().sections[sections.currentSection].content.title
          )}
        />

        <div className="general-separator"></div>
        <div className="general-separator"></div>
      </div>
      <div className="general-m-just-wide-screen">
        <div style={{ position: "relative" }}>
          <div className="mile-stone__container" id="mile-stone__container">
            {getContent().sections[
              sections.currentSection
            ].content.mileStones.map((mileStone, index) => (
              <Element name={`mile-stone-${index}`} key={index}>
                <MileStone
                  applyBorderClass={index === scrollOffset}
                  applyUpperIcon={mileStone.applyUpperIcon}
                  counterValue={mileStone.counterValue}
                  upperPoint={index % 2 === 0}
                  suffix={getRightContent(
                    generalSettings.rtlLanguage,
                    mileStone.suffix
                  )}
                  bottomText={getRightContent(
                    generalSettings.rtlLanguage,
                    mileStone.bottomText
                  )}
                  replaceCounter={getRightContent(
                    generalSettings.rtlLanguage,
                    mileStone.replaceCounter || ["", ""]
                  )}
                />
              </Element>
            ))}
            <div className="mile-stone__controller">
              <img
                src={upperIcon}
                alt=""
                className="miles-tone__controller-left"
                onClick={() =>
                  scrollOffset > 1 && setScrollOffset((prev) => prev - 1)
                }
              />
              <img
                src={upperIcon}
                alt=""
                className="miles-tone__controller-right"
                onClick={() =>
                  scrollOffset <
                    getContent().sections[sections.currentSection].content
                      .mileStones.length && setScrollOffset((prev) => prev + 1)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content2;
