import React, { useState } from "react";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import {
  getVisibleSensorAnimation,
  setAdditionalClasses,
} from "../assets/js/utils";

function CustomReadMore({
  text = [],
  textComplement = [],
  applyMainTextClassNameInIndex = { classNames: [], indexes: [] },
  applyReadMoreTogglerClassName,
}) {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore((prev) => !prev);
  };
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  return (
    <>
      {text.map((mainText, index) => (
        <VisibilitySensor partialVisibility key={index}>
          {({ isVisible }) => (
            <p
              className={setAdditionalClasses(
                ["mt-0", applyMainTextClassNameInIndex.classNames[index]],
                [
                  index === 0,
                  applyMainTextClassNameInIndex.indexes.includes(index),
                ]
              )}
              style={getVisibleSensorAnimation(
                isVisible,
                `fade-in-40px 1s ${index * 0.2}s backwards`
              )}
            >
              {mainText}
            </p>
          )}
        </VisibilitySensor>
      ))}
      {textComplement.map((paragraph, index) => (
        <VisibilitySensor partialVisibility key={index}>
          {({ isVisible }) => (
            <p
              className={`custom-read-more${setAdditionalClasses(
                ["show-text", "mb-20"],
                [!isReadMore, !isReadMore]
              )}`}
              style={getVisibleSensorAnimation(
                isVisible,
                `fade-in-40px 1s ${index * 0.2}s backwards`
              )}
            >
              {paragraph}
            </p>
          )}
        </VisibilitySensor>
      ))}
      {textComplement.length > 0 && (
        <span
          onClick={toggleReadMore}
          className={`custom-read-more__toggler${setAdditionalClasses(
            ["mt-20", applyReadMoreTogglerClassName],
            [!isReadMore, applyReadMoreTogglerClassName]
          )}`}
        >
          {isReadMore
            ? generalSettings.rtlLanguage
              ? "اقرأ المزيد"
              : "...read more"
            : generalSettings.rtlLanguage
            ? "عرض أقل"
            : "show less"}
        </span>
      )}
    </>
  );
}

export default CustomReadMore;
