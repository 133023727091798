import React from "react";

function StickyPerson({ FirstPart, SecondPart }) {
  return (
    <div className="sticky-person">
      <div className="sticky-person__first-part">
        {/* <h2 className="content-sub-title">
          {getRightContent(
            generalSettings.rtlLanguage,
            content.sections[sections.currentSection].content.chairmanAuthor
              .titleH2FirstAppeareance
          )}
        </h2> */}
        {FirstPart}
      </div>
      <div className="sticky-person__second-part">
        <div className="sticky-person__image-container">{SecondPart}</div>
      </div>
    </div>
  );
}

export default StickyPerson;
