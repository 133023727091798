import React from "react";

function ValueChain({ title, text, MiddleComponent }) {
  return (
    <div className="value-chain">
      <h3 className="value-chain__title">{title}</h3>
      {MiddleComponent}
      {text && <p className="value-chain__text">{text}</p>}
    </div>
  );
}

export default ValueChain;
