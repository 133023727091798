import content from "../content/content.json";

export const setAdditionalClasses = (classnames, conditions) => {
  let finalClasses = " ";
  for (let i = 0; i < classnames.length; i++) {
    if (conditions[i]) finalClasses += `${classnames[i]} `;
  }
  return finalClasses;
};

export const getRightContent = (rtl, contentList) =>
  rtl ? contentList[1] : contentList[0];

export const getVisibleSensorAnimation = (isVisible, animation) => ({
  animation: isVisible ? animation : "",
  opacity: !isVisible ? 0 : 1,
});

export const appendLeftZero = (number = 0) => {
  return (number + "").length === 1 ? `0${number}` : number;
};

export const getContent = () => content;

export const getDelayedIndexBasedAnimationTime = (
  windowWidth,
  index,
  indexDelay = 0.25,
  additionaldelay = 0
) => (windowWidth < 1024 ? 0 : index * indexDelay + additionaldelay);
