import mainImageHome from "../images/main-image-home.jpg";
import mainImageHomeCroppedTablet from "../images/main-image-home-cropped-tablet.jpg";
import mainImageHomeCroppedMobile from "../images/main-image-home-cropped-mobile.jpg";

import mainImageSection1CroppedMobile from "../images/main-image-1-section-cropped-mobile.jpg";
import mainImageSection1Croppedtablet from "../images/main-image-1-section-cropped-tablet.jpg";
import mainImageSection1 from "../images/main-image-1-section.jpg";

import mainImageSection2CroppedMobile from "../images/main-image-2-section-cropped-mobile.jpg";
import mainImageSection2Croppedtablet from "../images/main-image-2-section-cropped-tablet.jpg";
import mainImageSection2 from "../images/main-image-2-section.jpg";

import mainImageSection6CroppedMobile from "../images/main-image-6-section-cropped-mobile.jpg";
import mainImageSection6Croppedtablet from "../images/main-image-6-section-cropped-tablet.jpg";
import mainImageSection6 from "../images/main-image-6-section.jpg";

import mainImageSection7CroppedMobile from "../images/main-image-7-section-cropped-mobile.jpg";
import mainImageSection7Croppedtablet from "../images/main-image-7-section-cropped-tablet.jpg";
import mainImageSection7 from "../images/main-image-7-section.jpg";

import whiteBackground from "../images/white-background.jpg";

const homeImages = [
  mainImageHomeCroppedMobile,
  mainImageHomeCroppedTablet,
  mainImageHome,
];

const mainImagesSection1 = [
  mainImageSection1CroppedMobile,
  mainImageSection1Croppedtablet,
  mainImageSection1,
];

const mainImagesSection2 = [
  mainImageSection2CroppedMobile,
  mainImageSection2Croppedtablet,
  mainImageSection2,
];

const mainImagesSection3 = [whiteBackground, whiteBackground, whiteBackground];
const mainImagesSection4 = [whiteBackground, whiteBackground, whiteBackground];
const mainImagesSection5 = [whiteBackground, whiteBackground, whiteBackground];

const mainImagesSection6 = [
  mainImageSection6CroppedMobile,
  mainImageSection6Croppedtablet,
  mainImageSection6,
];

const mainImagesSection7 = [
  mainImageSection7CroppedMobile,
  mainImageSection7Croppedtablet,
  mainImageSection7,
];

const mainImages = [
  homeImages,
  mainImagesSection1,
  mainImagesSection2,
  mainImagesSection3,
  mainImagesSection4,
  mainImagesSection5,
  mainImagesSection6,
  mainImagesSection7,
];

export const getPreloadedImage = (sectionNumber) => {
  return mainImages[sectionNumber];
};

const whiteBackgroundSections = [false, false, false, true, true, true, false];
const greenBackgroundContents = [2];

export const whiteBackgroundsMainSection = (index) =>
  whiteBackgroundSections[index];

export const greenBackgroundContent = (index) =>
  greenBackgroundContents.includes(index);
