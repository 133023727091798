import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import {
  greenBackgroundContent,
  whiteBackgroundsMainSection,
} from "../assets/js/images";
import {
  getVisibleSensorAnimation,
  setAdditionalClasses,
} from "../assets/js/utils";

function ContentViewer({ Content }) {
  const sections = useSelector((state) => state.ui.sections);

  useEffect(() => {
    if (sections.sectionAlreadyChanged) {
      document.getElementById("content-viewer").scrollIntoView();
    }
  }, [sections.sectionAlreadyChanged]);
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <div
          id="content-viewer"
          className={`content-viewer${setAdditionalClasses(
            [
              "fixed-background content-viewer__background",
              "green-background",
              "content-7-background",
            ],
            [
              whiteBackgroundsMainSection(sections.currentSection),
              greenBackgroundContent(sections.currentSection),
              sections.currentSection === 7,
            ]
          )}`}
          style={getVisibleSensorAnimation(
            isVisible,
            "animated-content-background 1.5s backwards ease-out"
          )}
        >
          {Content}
        </div>
      )}
    </VisibilitySensor>
  );
}

export default ContentViewer;
