import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  getVisibleSensorAnimation,
  setAdditionalClasses,
} from "../assets/js/utils";
import SimpleMedia from "./SimpleMedia";
import milestonePoint from "../assets/images/mile-stone.png";

function MileStone({
  counterValue,
  bottomText,
  applyUpperIcon,
  upperPoint,
  replaceCounter,
  applyBorderClass,
  ...otherProps
}) {
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <div className="mile-stone__inner-container">
          {upperPoint && (
            <img
              src={milestonePoint}
              alt=""
              className="mile-stone__point upper"
            />
          )}
          <div style={{ overflow: "hidden", height: "100%" }}>
            <div
              className={`mile-stone${setAdditionalClasses(
                ["apply-upper-icon", "border-resalt"],
                [applyUpperIcon, applyBorderClass]
              )}`}
              style={getVisibleSensorAnimation(
                isVisible,
                `${
                  upperPoint ? "mile-stone-point-up" : "mile-stone-point-down"
                } 1s backwards ease-out`
              )}
            >
              <SimpleMedia
                counterValue={counterValue}
                bottomText={bottomText}
                delay={0.4}
                replaceCounter={replaceCounter}
                {...otherProps}
              />
            </div>
          </div>
          {!upperPoint && (
            <img src={milestonePoint} alt="" className="mile-stone__point" />
          )}
        </div>
      )}
    </VisibilitySensor>
  );
}

export default MileStone;
