import { createSlice } from "@reduxjs/toolkit";
import constants from "../../../assets/js/constants";
import { getContent } from "../../../assets/js/utils";

const slice = createSlice({
  name: `${constants.STATE_MANAGEMENT.AUXILIARY}/ui/sections`,
  initialState: {
    currentSection: 0,
    displayContent: false,
    readMorePressed: false,
    readMoreClosed: false,
    sectionAlreadyChanged: false,
    list: [],
    totalSections: getContent().sections.length,
  },
  reducers: {
    sectionChanged: (sections, action) => {
      sections.currentSection = action.payload;
      sections.sectionAlreadyChanged = true;
    },
    sectionsAdded: (sections, action) => {
      sections.list = action.payload;
    },
    sectionContentDisplayed: (sections, action) => {
      sections.displayContent = action.payload;
    },
    sectionReadMorePressed: (sections, action) => {
      sections.readMorePressed = !sections.readMorePressed;
    },
    sectionReadMoreClosed: (sections, action) => {
      sections.readMoreClosed = !sections.readMoreClosed;
    },
    sectionAlreadyChangedDeactivate: (sections, action) => {
      sections.sectionAlreadyChanged = false;
    },
  },
});

export const changeSection = (sectionNumber) => (dispatch) => {
  dispatch(sectionChanged(sectionNumber));
  setTimeout(() => {
    dispatch(sectionAlreadyChangedDeactivate());
  }, 1500);
};

export const addAllSections = (sections) => (dispatch, getState) => {
  if (getState().ui.sections.list.length === 0)
    dispatch(sectionsAdded(sections));
};

export const toggleSectionContentDisplay = (display) => (dispatch) => {
  dispatch(sectionContentDisplayed(display));
};

export const toggleReadMore = () => (dispatch) => {
  dispatch(sectionReadMorePressed());
};

export const toggleReadMoreClosed = () => (dispatch) => {
  dispatch(sectionReadMoreClosed());
};

export const {
  sectionChanged,
  sectionsAdded,
  sectionContentDisplayed,
  sectionReadMorePressed,
  sectionReadMoreClosed,
  sectionAlreadyChangedDeactivate,
} = slice.actions;
export default slice.reducer;
