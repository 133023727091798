import { Provider } from "react-redux";

import logo from "./logo.svg";
import "./App.css";
import configureStore from "./store/configureStore";
import MainContainer from "./components/MainContainer";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <MainContainer />
      </div>
    </Provider>
  );
}

export default App;
