import React from "react";
import { useSelector } from "react-redux";
import logoImage from "../assets/images/logo@2x.png";
import { setAdditionalClasses } from "../assets/js/utils";
import FileDownloader from "./FileDownloader";
import LanguageToggler from "./LanguageToggler";
import MenuIcon from "./MenuIcon";

function Navbar(props) {
  const sections = useSelector((state) => state.ui.sections);

  return (
    <div
      className={`navbar${setAdditionalClasses(
        ["animation-navbar-entry"],
        [sections.displayContent]
      )}`}
    >
      <img src={logoImage} alt="logo" className="navbar__logo" />
      <MenuIcon applyClassName="menu-icon" />
      <div className="language-download__container">
        <FileDownloader />
        <span>|</span>
        <LanguageToggler />
      </div>
    </div>
  );
}

export default Navbar;
