import React from "react";
import { setAdditionalClasses } from "../assets/js/utils";

function Card({ title, icon, description = [], hiddenIcon }) {
  return (
    <div className="card">
      <div className="card__title">{title}</div>
      <img
        src={icon}
        alt=""
        className={`card__icon${setAdditionalClasses(
          ["visible-hidden"],
          [hiddenIcon]
        )}`}
      />
      <div className="card__description">
        {description.map((desc, index) => (
          <div key={index} className="card__description-item">
            {desc}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Card;
