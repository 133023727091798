import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { setAdditionalClasses } from "../assets/js/utils";

function SimpleMedia({
  counterValue,
  bottomText,
  applyBottomTextClassName,
  prependTextIcon,
  replaceCounter,
  applyCounteClassName,
  ...otherprops
}) {
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <div className="simple-media">
          {isVisible ? (
            <div className={`simple-media__counter-value`}>
              {replaceCounter ? (
                <div
                  className={`simple-media__replace-counter ${applyCounteClassName}`}
                >
                  {replaceCounter}
                </div>
              ) : (
                <CountUp
                  end={counterValue}
                  start={0}
                  duration={0.6}
                  useEasing={true}
                  separator={","}
                  decimals={counterValue % 1 !== 0 ? 2 : 0}
                  className={applyCounteClassName}
                  {...otherprops}
                />
              )}
            </div>
          ) : null}
          <div className="simple-media__bottom-container">
            {prependTextIcon && (
              <img
                className="simple-media__prepend-text-icon"
                alt=""
                src={prependTextIcon}
              />
            )}
            <div
              className={`simple-media__bottom-text${setAdditionalClasses(
                [applyBottomTextClassName],
                [applyBottomTextClassName]
              )}`}
            >
              {bottomText}
            </div>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );
}

export default SimpleMedia;
