import React from "react";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import {
  getContent,
  getRightContent,
  getVisibleSensorAnimation,
} from "../assets/js/utils";
import Card from "./Card";
import ContentTitle from "./ContentTitle";
import ValueChain from "./ValueChain";
import ValueChainComponent from "./ValueChainComponent";
import IconDescriptor from "./IconDescriptor";
import HorizontalScrollable from "./HorizontalScrollable";
import Result from "./Result";
import IconDescriptorHorizontal from "./IconDescriptorHorizontal";

import industryValueChain1 from "../assets/images/industry-value-chain-1.svg";
import industryValueChain2 from "../assets/images/industry-value-chain-2.svg";
import industryValueChain3 from "../assets/images/industry-value-chain-3.svg";
import industryValueChain4 from "../assets/images/industry-value-chain-4.svg";
import industryValueChain5 from "../assets/images/industry-value-chain-5.svg";
import industryValueChain6 from "../assets/images/industry-value-chain-6.svg";
import industryValueChain7 from "../assets/images/industry-value-chain-7.svg";
import industryValueChain8 from "../assets/images/industry-value-chain-8.svg";
import industryValueChain9 from "../assets/images/industry-value-chain-9.svg";
import industryValueChain10 from "../assets/images/industry-value-chain-10.svg";
import industryValueChain11 from "../assets/images/industry-value-chain-11.svg";
import mapImage from "../assets/images/map.svg";
import primaryHen from "../assets/images/primary-hen.svg";
import primaryChick from "../assets/images/primary-chick.svg";
import textFarmingImage from "../assets/images/text-farming.png";

function Content6(props) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const sections = useSelector((state) => state.ui.sections);

  return (
    <div>
      <div className="general-m">
        <ContentTitle
          label={getRightContent(
            generalSettings.rtlLanguage,
            getContent().sections[sections.currentSection].content.title
          )}
        />

        <div className="general-separator"></div>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <p
              className="paragraph max-width-60"
              style={getVisibleSensorAnimation(
                isVisible,
                "fade-in-10vh 1s backwards"
              )}
            >
              {getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .mainParagraph
              )}
            </p>
          )}
        </VisibilitySensor>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <h2
              className="note__title"
              style={getVisibleSensorAnimation(
                isVisible,
                "fade-in-20vh 1.5s backwards"
              )}
            >
              {getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .firstTitle
              )}
            </h2>
          )}
        </VisibilitySensor>
      </div>
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <div
            className="general-m-just-wide-screen"
            style={getVisibleSensorAnimation(
              isVisible,
              `fade-in-10vh 1s backwards ease-out`
            )}
          >
            <HorizontalScrollable
              headerItems={[
                { label: "Farming" },
                { label: "Production" },
                { label: "Distribution" },
                { label: "Quick Service Restaurants" },
              ]}
              contentComponentsForHeader={[
                [
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Input",
                      "Input",
                    ])}
                    icon={industryValueChain1}
                    description={getRightContent(generalSettings.rtlLanguage, [
                      // [
                      //   <img
                      //     src={textFarmingImage}
                      //     alt=""
                      //     className="text-farming-image"
                      //   />,
                      // ],
                      // [
                      //   <img
                      //     src={textFarmingImage}
                      //     alt=""
                      //     className="text-farming-image"
                      //   />,
                      // ],
                      [
                        <Result
                          cause={"Breeders"}
                          effect={"Locally Sourced"}
                        />,
                        <Result cause={"Feed"} effect={"ADC Feed Mill"} />,
                        <Result cause={"Health Productions"} effect={"DHV"} />,
                      ],
                      [
                        <Result
                          cause={"Breeders"}
                          effect={"Locally Sourced"}
                        />,
                        <Result cause={"Feed"} effect={"ADC Feed Mill"} />,
                        <Result cause={"Health Productions"} effect={"DHV"} />,
                      ],
                    ])}
                  />,
                  <ValueChain
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Process",
                      "Process",
                    ])}
                    MiddleComponent={
                      <ValueChainComponent
                        applySeparator={true}
                        items={[
                          <IconDescriptor
                            icon={primaryHen}
                            text={getRightContent(generalSettings.rtlLanguage, [
                              "Breeder Farms",
                              "Breeder Farms",
                            ])}
                          />,
                          <IconDescriptor
                            icon={primaryChick}
                            text={getRightContent(generalSettings.rtlLanguage, [
                              "Hatcheries",
                              "Hatcheries",
                            ])}
                          />,
                          <IconDescriptor
                            icon={primaryHen}
                            text={getRightContent(generalSettings.rtlLanguage, [
                              "Broiler Farms",
                              "Broiler Farms",
                            ])}
                          />,
                        ]}
                      />
                    }
                    text={getRightContent(generalSettings.rtlLanguage, [
                      "ADC Farms supported by DHV Services",
                      "ADC Farms supported by DHV Services",
                    ])}
                  />,
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "End Result",
                      "End Result",
                    ])}
                    icon={industryValueChain2}
                    description={getRightContent(generalSettings.rtlLanguage, [
                      ["Live Poultry"],
                      ["Live Poultry"],
                    ])}
                  />,
                ],
                [
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Input",
                      "Input",
                    ])}
                    icon={industryValueChain2}
                    description={getRightContent(generalSettings.rtlLanguage, [
                      [<p>Live Poultry</p>, <p>Imported Meat</p>],
                      [<p>Live Poultry</p>, <p>Imported Meat</p>],
                    ])}
                  />,
                  <ValueChain
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Process",
                      "Process",
                    ])}
                    MiddleComponent={
                      <ValueChainComponent
                        // applySeparator={true}
                        items={[
                          <IconDescriptor
                            icon={industryValueChain4}
                            text={getRightContent(generalSettings.rtlLanguage, [
                              "Warehouses",
                              "Warehouses",
                            ])}
                          />,
                          <IconDescriptor
                            icon={industryValueChain5}
                            text={getRightContent(generalSettings.rtlLanguage, [
                              "Slaughterhouses",
                              "Slaughterhouses",
                            ])}
                          />,
                          <IconDescriptor
                            icon={industryValueChain6}
                            text={getRightContent(generalSettings.rtlLanguage, [
                              "FPP",
                              "FPP",
                            ])}
                          />,
                        ]}
                      />
                    }
                    text={getRightContent(generalSettings.rtlLanguage, [
                      "ADC",
                      "ADC",
                    ])}
                  />,
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "End Result",
                      "End Result",
                    ])}
                    icon={industryValueChain3}
                    description={getRightContent(generalSettings.rtlLanguage, [
                      [
                        <p>Chicken (whole, parts, frsh, frozen, etc.)</p>,
                        <p>Processed Poultry and Meat</p>,
                      ],
                      [
                        <p>Chicken (whole, parts, frsh, frozen, etc.)</p>,
                        <p>Processed Poultry and Meat</p>,
                      ],
                    ])}
                  />,
                ],
                [
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Input",
                      "Input",
                    ])}
                    icon={industryValueChain3}
                    description={getRightContent(generalSettings.rtlLanguage, [
                      [
                        <p>Chicken (whole, parts, frsh, frozen, etc.)</p>,
                        <p>Processed Poultry and Meat</p>,
                      ],
                      [
                        <p>Chicken (whole, parts, frsh, frozen, etc.)</p>,
                        <p>Processed Poultry and Meat</p>,
                      ],
                    ])}
                  />,
                  <ValueChain
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Process",
                      "Process",
                    ])}
                    MiddleComponent={
                      <ValueChainComponent
                        // applySeparator={true}
                        items={[
                          <>
                            <p className="color-white medium font-size-7 text-center map-text">
                              {getRightContent(generalSettings.rtlLanguage, [
                                "Tanmiah Dsitribution Channels",
                                "Tanmiah Dsitribution Channels",
                              ])}
                            </p>
                          </>,
                          <img src={mapImage} alt="" className="map-image" />,
                          <p className="color-secondary medium font-size-7 text-center map-text">
                            {getRightContent(generalSettings.rtlLanguage, [
                              "Client Base",
                              "Client Base",
                            ])}
                          </p>,
                        ]}
                      />
                    }
                  />,
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "End Result",
                      "End Result",
                    ])}
                    description={[
                      <div className="vertical-inner-separator">
                        <IconDescriptorHorizontal
                          icon={industryValueChain7}
                          text={getRightContent(generalSettings.rtlLanguage, [
                            "Distributors",
                            "Distributors",
                          ])}
                        />
                      </div>,
                      <div className="vertical-inner-separator">
                        <IconDescriptorHorizontal
                          icon={industryValueChain8}
                          text={getRightContent(generalSettings.rtlLanguage, [
                            "Retailers",
                            "Retailers",
                          ])}
                        />
                      </div>,
                      <div className="vertical-inner-separator">
                        <IconDescriptorHorizontal
                          icon={industryValueChain9}
                          text={getRightContent(generalSettings.rtlLanguage, [
                            "HORECA",
                            "HORECA",
                          ])}
                        />
                      </div>,
                    ]}
                  />,
                ],
                [
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Input",
                      "Input",
                    ])}
                    icon={industryValueChain10}
                    description={getRightContent(generalSettings.rtlLanguage, [
                      [<p>Fresh (Chicken bone in and boneless parts)</p>],
                      [<p>Fresh (Chicken bone in and boneless parts)</p>],
                    ])}
                  />,
                  <ValueChain
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "Process",
                      "Process",
                    ])}
                    MiddleComponent={
                      <ValueChainComponent
                        // applySeparator={true}
                        columnDisplay={true}
                        items={[
                          <>
                            <p className="color-white medium font-size-7 text-center">
                              {getRightContent(generalSettings.rtlLanguage, [
                                "Retail Consumers",
                                "Retail Consumers",
                              ])}
                            </p>
                          </>,
                          <p className="color-white medium font-size-7 text-center">
                            {getRightContent(generalSettings.rtlLanguage, [
                              "Drive Through",
                              "Drive Through",
                            ])}
                          </p>,
                          <p className="color-white medium font-size-7 text-center">
                            {getRightContent(generalSettings.rtlLanguage, [
                              "Online Delivery",
                              "Online Delivery",
                            ])}
                          </p>,
                        ]}
                      />
                    }
                  />,
                  <Card
                    title={getRightContent(generalSettings.rtlLanguage, [
                      "End Result",
                      "End Result",
                    ])}
                    hiddenIcon={true}
                    icon={industryValueChain10}
                    description={[
                      <IconDescriptorHorizontal
                        icon={industryValueChain11}
                        text={getRightContent(generalSettings.rtlLanguage, [
                          "Fried Chicken Sandwiches",
                          "Fried Chicken Sandwiches",
                        ])}
                      />,
                    ]}
                  />,
                ],
              ]}
            />
          </div>
        )}
      </VisibilitySensor>
    </div>
  );
}

export default Content6;
