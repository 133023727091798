import React from "react";

function IconDescriptor({ icon, text }) {
  return (
    <div className="icon-descriptor">
      <img src={icon} alt="" className="icon-descriptor__icon" />
      <span className="icon-descriptor__text">{text}</span>
    </div>
  );
}

export default IconDescriptor;
