import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import VisibilitySensor from "react-visibility-sensor";
import {
  getContent,
  getDelayedIndexBasedAnimationTime,
  getRightContent,
  getVisibleSensorAnimation,
} from "../assets/js/utils";
import CardHover from "./CardHover";
import ContentTitle from "./ContentTitle";
import useWindowSize from "./hooks/useWindowSize";
import Note from "./Note";
import SimpleMedia from "./SimpleMedia";
import HorizontalScrollable from "./HorizontalScrollable";
import Card from "./Card";

import financialHighlightsIcon1 from "../assets/images/financial-high-1.svg";
import financialHighlightsIcon2 from "../assets/images/financial-high-2.svg";
import financialHighlightsIcon3 from "../assets/images/financial-high-3.svg";
import financialHighlightsIcon4 from "../assets/images/financial-high-4.svg";
import financialHighlightsIcon5 from "../assets/images/financial-high-5.svg";
import financialHighlightsIcon6 from "../assets/images/financial-high-6.svg";

import Result from "./Result";
import ValueChain from "./ValueChain";
import ValueChainComponent from "./ValueChainComponent";
import IconDescriptor from "./IconDescriptor";

import IconDescriptorHorizontal from "./IconDescriptorHorizontal";
import constants from "../assets/js/constants";
import ProgressBar from "./ProgressBar";

const financialHighlightsIcons = [
  financialHighlightsIcon1,
  financialHighlightsIcon2,
  financialHighlightsIcon3,
  financialHighlightsIcon4,
  financialHighlightsIcon5,
  financialHighlightsIcon6,
];

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//   labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
//   datasets: [
//     {
//       label: "# of Votes",
//       data: [84.4, 2],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "rgba(75, 192, 192, 0.2)",
//         "rgba(153, 102, 255, 0.2)",
//         "rgba(255, 159, 64, 0.2)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//         "rgba(153, 102, 255, 1)",
//         "rgba(255, 159, 64, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

function Content1(props) {
  const [activeItem, setActiveItem] = useState(0);
  const [percentages, setPercentages] = useState([84.92, 0, 0, 0]);
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const sections = useSelector((state) => state.ui.sections);
  const windowWidth = useWindowSize();

  return (
    <>
      <div className="general-m">
        <ContentTitle
          label={getRightContent(
            generalSettings.rtlLanguage,
            getContent().sections[sections.currentSection].content.title
          )}
        />

        <div className="general-separator"></div>
      </div>
      <div className="general-m-just-wide-screen">
        <Note
          title={getRightContent(
            generalSettings.rtlLanguage,
            getContent().sections[sections.currentSection].content
              .operationalHighlights.title
          )}
          components={getContent().sections[
            sections.currentSection
          ].content.operationalHighlights.components.map((component, index) => (
            <SimpleMedia
              key={index}
              counterValue={component.counterValue}
              bottomText={getRightContent(
                generalSettings.rtlLanguage,
                component.bottomText
              )}
              suffix={component.suffix}
              delay={getDelayedIndexBasedAnimationTime(
                windowWidth,
                index,
                0.25,
                1.1
              )}
            />
          ))}
        />
      </div>

      <div className="general-m">
        <div className="general-separator"></div>
        <h2 className="note__title">
          {getRightContent(
            generalSettings.rtlLanguage,
            getContent().sections[sections.currentSection].content
              .financialHighlights.title
          )}
        </h2>
        <div className="content1__cards-container">
          {getContent().sections[
            sections.currentSection
          ].content.financialHighlights.cards.map((card, index) => (
            <VisibilitySensor partialVisibility key={index}>
              {({ isVisible }) => (
                <div
                  className="content1__card-item"
                  style={getVisibleSensorAnimation(
                    isVisible,
                    `fade-in-20vh 1s ${getDelayedIndexBasedAnimationTime(
                      windowWidth,
                      index
                    )}s backwards`
                  )}
                >
                  <CardHover
                    title={getRightContent(
                      generalSettings.rtlLanguage,
                      card.title
                    )}
                    icon={financialHighlightsIcons[index]}
                    components={card.components.map((component, compIndex) => (
                      <div
                        key={compIndex}
                        className="content1__card-bottom-container"
                      >
                        <div className="content1__card-year">
                          {component.year}
                        </div>
                        <SimpleMedia
                          counterValue={component.counterValue}
                          bottomText={getRightContent(
                            generalSettings.rtlLanguage,
                            component.bottomText
                          )}
                          applyBottomTextClassName="color-dark font-size-6"
                        />
                      </div>
                    ))}
                  />
                </div>
              )}
            </VisibilitySensor>
          ))}
        </div>

        <div className="general-separator"></div>

        <div className="general-m">
          <h2 className="note__title">
            {getRightContent(
              generalSettings.rtlLanguage,
              getContent().sections[sections.currentSection].content
                .revenueSegmentation.title
            )}
          </h2>
          <div className="content1__progressBar-container">
            {getContent().sections[
              sections.currentSection
            ].content.revenueSegmentation.circleProgressBars.map(
              (circleProgressBar, index) => (
                <VisibilitySensor partialVisibility key={index}>
                  {({ isVisible }) => (
                    <div
                      className="content1__progressBar-item"
                      style={getVisibleSensorAnimation(
                        isVisible,
                        `fade-in-20vh 1s ${getDelayedIndexBasedAnimationTime(
                          windowWidth,
                          index
                        )}s backwards`
                      )}
                    >
                      <ProgressBar
                        percentage={circleProgressBar.percentage}
                        speed={circleProgressBar.speed}
                        delay={1000}
                        isVisible={isVisible}
                        label={getRightContent(
                          generalSettings.rtlLanguage,
                          circleProgressBar.label
                        )}
                        strokeColor={circleProgressBar.strokeColor}
                      />
                    </div>
                  )}
                </VisibilitySensor>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Content1;
