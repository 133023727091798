import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalClasses } from "../assets/js/utils";
import { toggleMenu } from "../store/auxiliary/ui/generalSettings";
import {
  changeSection,
  toggleSectionContentDisplay,
} from "../store/auxiliary/ui/sections";

function Menu({ menuItems = [] }) {
  const sections = useSelector((state) => state.ui.sections);
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const dispatch = useDispatch();

  const handleClickMenuItem = (menuIndex) => {
    // window.scroll(0, 0);
    dispatch(changeSection(menuIndex));
    dispatch(toggleMenu());
    dispatch(toggleSectionContentDisplay(true));

    // document
    //   .getElementById(`section-${menuIndex}`)
    //   .scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="menu">
      <ul className="list">
        {menuItems.map((menu, index) => (
          <li
            key={index}
            className={`list__item${setAdditionalClasses(
              ["active"],
              [index + 1 === sections.currentSection]
            )}`}
            onClick={() => handleClickMenuItem(index + 1)}
          >
            {menu}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Menu;
