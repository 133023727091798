import React, { useEffect, useRef, useState } from "react";
import HorizontalScrollableList from "./HorizontalScrollableList";
import VisibilitySensor from "react-visibility-sensor";
import useWindowSize from "./hooks/useWindowSize";
import {
  getDelayedIndexBasedAnimationTime,
  getVisibleSensorAnimation,
} from "../assets/js/utils";

function HorizontalScrollable({
  contentComponentsForHeader = [],
  headerItems = [],
}) {
  const [activeItem, setActiveItem] = useState(0);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const windowWidth = useWindowSize();
  const headerRef = useRef();

  useEffect(() => {
    setComponentLoaded(true);
  }, []);
  useEffect(() => {
    if (headerRef.current && componentLoaded && windowWidth < 600) {
      headerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeItem, componentLoaded, windowWidth]);

  const handlePressHeaderItem = (activeHeaderIndex) => {
    setActiveItem(activeHeaderIndex);
  };
  return (
    <div className="horizontal-scrollable">
      <div
        ref={headerRef}
        style={{
          height: "1px",
          zIndex: -1,
          position: "relative",
          bottom: "60px",
        }}
      ></div>
      <HorizontalScrollableList
        items={headerItems}
        activeItem={activeItem}
        onPressItem={handlePressHeaderItem}
      />

      {contentComponentsForHeader.map((content, indexHeader) => (
        <div
          className="horizontal-scrollable__content-container"
          key={indexHeader}
        >
          {indexHeader === activeItem &&
            content.map((Component, index) => (
              <VisibilitySensor partialVisibility key={index}>
                {({ isVisible }) => (
                  <div
                    key={index}
                    className="horizontal-scrollable__content-item"
                    style={getVisibleSensorAnimation(
                      isVisible,
                      `fade-in-20vh 1s ${getDelayedIndexBasedAnimationTime(
                        windowWidth,
                        index
                      )}s backwards`
                    )}
                  >
                    {Component}
                  </div>
                )}
              </VisibilitySensor>
            ))}
        </div>
      ))}
    </div>
  );
}

export default HorizontalScrollable;
