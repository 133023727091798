import React from "react";
import { setAdditionalClasses } from "../assets/js/utils";

function HorizontalScrollableList({ items = [], onPressItem, activeItem = 0 }) {
  return (
    <div className="horizontal-scrollable-list">
      {items.map((item, index) => (
        <span
          key={index}
          className={`horizontal-scrollable-list__item${setAdditionalClasses(
            ["active"],
            [activeItem === index]
          )}`}
          onClick={() => onPressItem(index)}
        >
          {item.label}
        </span>
      ))}
    </div>
  );
}

export default HorizontalScrollableList;
