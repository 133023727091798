import useWindowSize from "./useWindowSize";

const useResponsiveImage = (images) => {
  const windowWidth = useWindowSize();
  if (windowWidth < 500) return images[0];
  else if (windowWidth < 1023) return images[1];
  else return images[2];
};

export default useResponsiveImage;
