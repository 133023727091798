import React from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../assets/js/constants";
import { toggleLanguage } from "../store/auxiliary/ui/generalSettings";

function LanguageToggler(props) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const dispatch = useDispatch();

  const handleLanguageToggler = () => {
    dispatch(toggleLanguage());
    if (!generalSettings.rtlLanguage) {
      constants.ROOT_ELEMENT.dataset.languageDirection = "right";
    } else {
      delete constants.ROOT_ELEMENT.dataset.languageDirection;
    }
  };
  return (
    <span className="language-toggler" onClick={handleLanguageToggler}>
      {generalSettings.rtlLanguage ? "English" : "العربية"}
    </span>
  );
}

export default LanguageToggler;
