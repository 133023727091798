import React, { useState } from "react";
import cardArrow from "../assets/images/card-arrow.svg";
import { setAdditionalClasses } from "../assets/js/utils";

function CardHover({ icon, title, components = [] }) {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleHover = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };
  return (
    <div
      className={`${setAdditionalClasses(["active"], [isActive])}card-hover`}
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <div
        className={`card-hover__wrapper${setAdditionalClasses(
          ["active"],
          [isActive]
        )}`}
      >
        <div className="card-hover__icon-container">
          <img
            src={icon}
            alt=""
            className={`card-hover__icon${setAdditionalClasses(
              ["active"],
              [isActive]
            )}`}
          />
        </div>
        <h3
          className={`card-hover__title${setAdditionalClasses(
            ["active"],
            [isActive]
          )}`}
        >
          {title}
        </h3>
        <div
          className={`card-hover__components${setAdditionalClasses(
            ["active"],
            [isActive]
          )}`}
        >
          {components.map((component, index) => (
            <div key={index} className="card-hover__item-component">
              {component}
            </div>
          ))}
        </div>
      </div>
      <img
        src={cardArrow}
        alt=""
        className={`card-hover__arrow${setAdditionalClasses(
          ["active"],
          [isActive]
        )}`}
      />
    </div>
  );
}

export default CardHover;
