import React from "react";
import { useSelector } from "react-redux";
import useWindowSize from "./hooks/useWindowSize";
import downloadIcon from "../assets/images/download_icon.svg";

function FileDownloader(props) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const windowWidth = useWindowSize();
  return (
    <div className="file-downloader">
      {windowWidth < 1024 ? (
        <img src={downloadIcon} alt="" />
      ) : generalSettings.rtlLanguage ? (
        "مركز التحميل"
      ) : (
        "DOWNLOAD"
      )}
    </div>
  );
}

export default FileDownloader;
