import React from "react";
import arrowPrimary from "../assets/images/arrow-primary.svg";

function Result({ cause, effect }) {
  return (
    <div className="result">
      <span>{cause}</span>
      <img src={arrowPrimary} alt="" className="result__icon" />
      <span>{effect}</span>
    </div>
  );
}

export default Result;
