import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { getVisibleSensorAnimation } from "../assets/js/utils";

function Media({ icon, SimpleMediaComponent }) {
  return (
    <div className="media">
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <img
            src={icon}
            className="media__icon"
            alt=""
            style={getVisibleSensorAnimation(
              isVisible,
              "scale-icon 1s 0.7s ease-in-out"
            )}
          />
        )}
      </VisibilitySensor>
      {SimpleMediaComponent}
    </div>
  );
}

export default Media;
