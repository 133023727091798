const constants = {
  ROOT_ELEMENT: document.querySelector("#root"),
  STATE_MANAGEMENT: {
    AUXILIARY: "tanmiah/auxiliary",
  },
  COLORS: {
    PRIMARY: "#036936",
    PRIMARY_LIGHT: "#089247",
    PRIMARY_LIGHTER: "#F3F8F4",
    SECONDARY: "#fcaa3c",
    DARK: "#2b2e34",
    DARK_GRAY: "#6E6861",
  },
};

export default constants;
