import React from "react";
import { useSelector } from "react-redux";
import content from "../assets/content/content.json";
import { getRightContent, setAdditionalClasses } from "../assets/js/utils";

function ReadMore({ onPress, whiteBackgroundColor }) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  return (
    <div
      className={`read-more${setAdditionalClasses(
        ["white-background"],
        [whiteBackgroundColor]
      )}`}
      onClick={onPress}
    >
      <div
        className={`read-more__line${setAdditionalClasses(
          ["white-background"],
          [whiteBackgroundColor]
        )}`}
      ></div>
      <div
        className={`read-more__label${setAdditionalClasses(
          ["white-background"],
          [whiteBackgroundColor]
        )}`}
      >
        {getRightContent(
          generalSettings.rtlLanguage,
          content.generalTerms.readMore
        )}
      </div>
    </div>
  );
}

export default ReadMore;
