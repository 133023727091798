import { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { changeSection } from "../../store/auxiliary/ui/sections";

const thresholdToChangeSectionInMs = 2000;
// const totalNumberOfSections = 7;

const useSectionsHandler = (handleScroller) => {
  const [clientY, setClientY] = useState(null);
  const [clientX, setClientX] = useState(null);
  const [canChangeSection, setCanChangeSection] = useState(true);

  const sections = useSelector((state) => state.ui.sections);
  const dispatch = useDispatch();

  useEffect(() => {
    function onWheel(e) {
      if (!sections.displayContent) {
        if (e.deltaY > 0) {
          if (canChangeSection) handleNextSection();
        } else if (e.deltaY < 0) {
          if (canChangeSection) handlePrevSection();
        }
      }
    }

    function onTouchStart(e) {
      if (!sections.displayContent) {
        setClientX(e.touches[0].clientX);
        setClientY(e.touches[0].clientY);
        // setStartMouseDown(true);
      }
    }

    function onTouchEnd(e) {
      if (!sections.displayContent && clientY) {
        const xDiff = clientX - e.changedTouches[0].clientX;
        const yDiff = clientY - e.changedTouches[0].clientY;
        const isVerticalSwipe = Math.abs(yDiff) > Math.abs(xDiff);
        if (isVerticalSwipe) {
          //   if (xDiff > 100 && canChangeSection) setSwipeRight(true);
          //   else if (xDiff < -100 && canChangeSection) setSwipeLeft(true);

          //   setClientX(null);
          // } else {
          if (canChangeSection) {
            //   handleReadMore();
            if (yDiff < -100) handlePrevSection();
            else if (yDiff > 100) handleNextSection();
          }
          setClientY(null);
        }
      }
    }

    window.addEventListener("wheel", onWheel);
    window.addEventListener("touchstart", onTouchStart, false);
    window.addEventListener("touchend", onTouchEnd, false);

    return function () {
      window.removeEventListener("wheel", onWheel);
      window.removeEventListener("touchstart", onTouchStart);
      window.removeEventListener("touchend", onTouchEnd);
    };
  }, [sections.displayContent, canChangeSection, clientY, clientX]);

  const handleNextSection = () => {
    const currentSection = sections.currentSection;
    if (currentSection < sections.totalSections - 1) {
      dispatch(changeSection(currentSection + 1));
      handleAnchorId(currentSection + 1, true);
      delaySectionsChange();
    }
  };

  const handlePrevSection = () => {
    const currentSection = sections.currentSection;
    if (currentSection > 0) {
      dispatch(changeSection(currentSection - 1));
      handleAnchorId(currentSection - 1, false);
      delaySectionsChange();
    }
  };

  const delaySectionsChange = () => {
    setCanChangeSection(false);
    setTimeout(() => {
      setCanChangeSection(true);
    }, thresholdToChangeSectionInMs);
  };

  const handleAnchorId = (sectionNumber, movingForward) => {
    const contentWrapper = document.getElementById(`content-area__wrapper`);
    if (canChangeSection) {
      // if (movingForward) {
      //   contentWrapper.className = "";
      //   contentWrapper.classList.add(
      //     `animate-section-transition-${sectionNumber}`
      //   );
      // } else {
      //   contentWrapper.className = "";
      //   contentWrapper.classList.add(
      //     `animate-section-transition-prev-${sectionNumber + 1}`
      //   );
      // }

      // handleScroller(sectionNumber);
      console.log("scroll to ", sectionNumber);
      scroller.scrollTo(`scroll-section-${sectionNumber}`, {
        duration: 1500,
        ignoreCancelEvents: true,
        // delay: 100,
        smooth: "easeInCubic",
        containerId: "content-area__wrapper",
        // offset: 50,
      });
    }
  };
};

export default useSectionsHandler;
