import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalClasses } from "../assets/js/utils";
import {
  changeSection,
  toggleSectionContentDisplay,
} from "../store/auxiliary/ui/sections";
import ContentArea from "./ContentArea";
import useSectionsHandler from "./hooks/useSectionsHandler";
import Navbar from "./Navbar";

function MainContainer(props) {
  const sections = useSelector((state) => state.ui.sections);
  const dispatch = useDispatch();
  // useSectionsHandler();

  return (
    <div
      className={`main-container${setAdditionalClasses(
        ["animation-hide-show-main-container"],
        [sections.readMorePressed || sections.readMoreClosed]
      )}`}
    >
      <Navbar />
      <ContentArea />
      {/* {!sections.displayContent && <ContentArea />}
      {sections.displayContent && (
        <div style={{ height: "100vh", backgroundColor: "yellow" }}>
          <p>display content</p>
          <button
            style={{ margin: "auto" }}
            onClick={() => dispatch(toggleSectionContentDisplay(false))}
          >
            Close
          </button>
        </div>
      )} */}
    </div>
  );
}

export default MainContainer;
