import React from "react";
import { setAdditionalClasses } from "../assets/js/utils";

function ValueChainComponent({ items, applySeparator, columnDisplay }) {
  return (
    <div
      className={`value-chain-container${setAdditionalClasses(
        ["flex-column"],
        [columnDisplay]
      )}`}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={`value-chain-container__item${setAdditionalClasses(
            ["m-0"],
            [index === 1]
          )}`}
        >
          {applySeparator && index === 1 ? (
            <div className="value-chain-container__separator-container">
              <div className="value-chain-container__separator"></div>
              {item}
              <div className="value-chain-container__separator"></div>
            </div>
          ) : (
            item
          )}
        </div>
      ))}
    </div>
  );
}

export default ValueChainComponent;
