import React from "react";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import {
  getContent,
  getDelayedIndexBasedAnimationTime,
  getRightContent,
  getVisibleSensorAnimation,
  setAdditionalClasses,
} from "../assets/js/utils";
import CustomReadMore from "./CustomReadMore";

import useWindowSize from "./hooks/useWindowSize";
import SimpleMedia from "./SimpleMedia";
import StickyPerson from "./StickyPerson";

import cfoImage from "../assets/images/cfo-3.png";
import ellipseSecondary from "../assets/images/ellipse-secondary.svg";
import ellipseGray from "../assets/images/ellipse-gray.svg";

function Content5(props) {
  const sections = useSelector((state) => state.ui.sections);
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const windowWidth = useWindowSize();
  return (
    <div>
      <div className="general-m">
        <StickyPerson
          FirstPart={
            <>
              <div className="content-4__media-container">
                {getContent().sections[
                  sections.currentSection
                ].content.cfoAuthor.simpleMediaItems.map(
                  (simpleMedia, index) => (
                    <VisibilitySensor partialVisibility key={index}>
                      {({ isVisible }) => (
                        <div
                          className={`content-4__media-container-item${setAdditionalClasses(
                            ["m-0"],
                            [simpleMedia.blank]
                          )}`}
                          key={index}
                          style={getVisibleSensorAnimation(
                            isVisible,
                            `fade-in-40px 1s ${getDelayedIndexBasedAnimationTime(
                              windowWidth,
                              index
                            )}s backwards`
                          )}
                        >
                          {simpleMedia.blank ? (
                            <div
                              style={{ height: "1px", width: "200px" }}
                            ></div>
                          ) : (
                            <SimpleMedia
                              counterValue={simpleMedia.counterValue}
                              suffix={getRightContent(
                                generalSettings.rtlLanguage,
                                simpleMedia.suffix
                              )}
                              prefix={getRightContent(
                                generalSettings.rtlLanguage,
                                simpleMedia.prefix || ["", ""]
                              )}
                              bottomText={getRightContent(
                                generalSettings.rtlLanguage,
                                simpleMedia.bottomText
                              )}
                              applyBottomTextClassName="color-dark"
                              applyCounteClassName={
                                simpleMedia.colorSecondary
                                  ? "color-secondary"
                                  : "color-primary"
                              }
                            />
                          )}
                        </div>
                      )}
                    </VisibilitySensor>
                  )
                )}
              </div>
              <p className="quote-paragraph">
                {
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.quoteParagraph1
                }
              </p>

              <CustomReadMore
                text={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore1.text
                )}
                textComplement={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore1.complement
                )}
              />

              <h2 className="content-sub-title">
                {getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.subTitle1
                )}
              </h2>

              <h3 className="content-sub-title color-dark">
                {getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.titleH2CustomReadMore2
                )}
              </h3>

              <CustomReadMore
                text={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore2.text
                )}
                textComplement={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore2.complement
                )}
              />

              <h3 className="content-sub-title color-dark">
                {getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.titleH2CustomReadMore3
                )}
              </h3>

              <CustomReadMore
                text={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore3.text
                )}
                textComplement={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore3.complement
                )}
              />

              <h2 className="content-sub-title">
                {getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.titleH2CustomReadMore4
                )}
              </h2>

              <CustomReadMore
                text={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore4.text
                )}
                textComplement={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore4.complement
                )}
              />

              <h2 className="content-sub-title">
                {getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.titleH2CustomReadMore5
                )}
              </h2>

              <CustomReadMore
                text={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore5.text
                )}
                textComplement={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore5.complement
                )}
              />

              <h2 className="content-sub-title">
                {getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.titleH2CustomReadMore6
                )}
              </h2>

              <CustomReadMore
                text={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore6.text
                )}
                textComplement={getRightContent(
                  generalSettings.rtlLanguage,
                  getContent().sections[sections.currentSection].content
                    .cfoAuthor.customReadMore6.complement
                )}
              />
            </>
          }
          SecondPart={
            <VisibilitySensor partialVisibility>
              {({ isVisible }) => (
                <div className="content-3__person-image-container">
                  <img
                    src={cfoImage}
                    alt="Tanmiah's chairman"
                    className="sticky-person__image"
                    style={getVisibleSensorAnimation(
                      isVisible,
                      "person-move 1s backwards ease-in"
                    )}
                  />
                  <img
                    src={ellipseSecondary}
                    alt=""
                    className="ellipse-secondary-image"
                    style={getVisibleSensorAnimation(
                      isVisible,
                      "ellipse-secondary-move 1s  ease-in backwards"
                    )}
                  />

                  <img
                    src={ellipseGray}
                    alt=""
                    className="ellipse-primary-image"
                    style={getVisibleSensorAnimation(
                      isVisible,
                      "ellipse-primary-move 1.1s  ease-in backwards"
                    )}
                  />
                  <div className="section-descriptor__image-details">
                    <div
                      className="section-descriptor__image-details-name"
                      style={getVisibleSensorAnimation(
                        isVisible,
                        `fade-in-scale 1.5s 1s backwards`
                      )}
                    >
                      {getRightContent(
                        generalSettings.rtlLanguage,
                        getContent().sections[sections.currentSection].content
                          .cfoAuthor.name
                      )}
                    </div>
                    <div
                      className="section-descriptor__image-details-position"
                      style={getVisibleSensorAnimation(
                        isVisible,
                        `fade-in-20vh 1.5s 1s backwards`
                      )}
                    >
                      {getRightContent(
                        generalSettings.rtlLanguage,
                        getContent().sections[sections.currentSection].content
                          .cfoAuthor.job
                      )}
                    </div>
                  </div>
                </div>
              )}
            </VisibilitySensor>
          }
        />
      </div>
    </div>
  );
}

export default Content5;
