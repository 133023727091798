import React from "react";
import { useSelector } from "react-redux";
import { getContent, getRightContent } from "../assets/js/utils";
import ContentTitle from "./ContentTitle";
import CustomReadMore from "./CustomReadMore";

import tanmiahPillarsImage from "../assets/images/tanmiah-pillars.png";

function Content7(props) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const sections = useSelector((state) => state.ui.sections);
  return (
    <div>
      <div className="general-m">
        <div className="content-7__sticky-container">
          <div className="content-7__title-container">
            <ContentTitle
              label={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content.title
              )}
            />
          </div>
          <div className="content-7__text-container mt-40-70">
            <CustomReadMore
              text={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .customReadMore1.text
              )}
              textComplement={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .customReadMore1.complement
              )}
            />

            <h2 className="content-sub-title color-secondary">
              {getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .titleH2CustomReadMore2
              )}
            </h2>
            <CustomReadMore
              text={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .customReadMore2.text
              )}
              textComplement={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .customReadMore2.complement
              )}
              applyReadMoreTogglerClassName="color-white"
            />

            <h2 className="content-sub-title color-secondary">
              {getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .titleH2CustomReadMore3
              )}
            </h2>
            <CustomReadMore
              text={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .customReadMore3.text
              )}
              textComplement={getRightContent(
                generalSettings.rtlLanguage,
                getContent().sections[sections.currentSection].content
                  .customReadMore3.complement
              )}
              applyReadMoreTogglerClassName="color-white"
            />
          </div>
        </div>

        <div className="general-separator"></div>
        <img
          src={tanmiahPillarsImage}
          alt="tanmiah-pillars"
          className="content-7__pillars-image"
        />
      </div>
    </div>
  );
}

export default Content7;
