import React, { useEffect, useState } from "react";
import { appendLeftZero } from "../assets/js/utils";

function SectionIndicator({
  currentSection = 0,
  numberOfSections = 1,
  backgroundColor = "transparent",
  color = "white",
  height = 100,
  barColorRGBA = "rgba(255,255,255,0.5)",
}) {
  const barHeight = Math.round(height / numberOfSections);
  const [applyAnimation, setApplyAnimation] = useState(false);

  useEffect(() => {
    if (!applyAnimation) {
      setApplyAnimation(true);
      setTimeout(() => {
        setApplyAnimation(false);
      }, 1000);
    }
  }, [currentSection]);
  return (
    <div className="section-indicator" style={{ backgroundColor, color }}>
      <div
        className={`section-indicator__label ${
          applyAnimation ? "animation-hide-show" : ""
        }`}
      >
        {appendLeftZero(currentSection + 1)}
      </div>
      <div
        className="section-indicator__bar-body"
        style={{
          backgroundColor: barColorRGBA,
          height: height + "px",
        }}
      >
        <div
          className="section-indicator__marker"
          style={{
            backgroundColor: color,
            top: barHeight * currentSection,
            height: barHeight,
          }}
        ></div>
      </div>
      <div className="section-indicator__label">
        {appendLeftZero(numberOfSections)}
      </div>
    </div>
  );
}

export default SectionIndicator;
