import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { whiteBackgroundsMainSection } from "../assets/js/images";
import { setAdditionalClasses } from "../assets/js/utils";
import { toggleMenu } from "../store/auxiliary/ui/generalSettings";

function MenuIcon({ applyClassName = "" }) {
  const generalSettings = useSelector((state) => state.ui.generalSettings);
  const sections = useSelector((state) => state.ui.sections);
  const dispatch = useDispatch();
  return (
    <div
      className={`menu-icon__container${setAdditionalClasses(
        ["menu-icon__active", applyClassName],
        [generalSettings.menuOpen, applyClassName]
      )}`}
      onClick={() => dispatch(toggleMenu())}
    >
      <div
        className={`menu-icon__label${setAdditionalClasses(
          ["rise-label"],
          [generalSettings.menuOpen]
        )}`}
      >
        {generalSettings.rtlLanguage ? "القائمة" : "MENU"}
      </div>
      <div
        className={`icon nav-icon-5${setAdditionalClasses(
          [
            "open",
            "menu-color-secondary",
            "menu-color-primary",
            "menu-color-white",
          ],
          [
            generalSettings.menuOpen,
            sections.displayContent,
            whiteBackgroundsMainSection(sections.currentSection),
            whiteBackgroundsMainSection(sections.currentSection) &&
              generalSettings.menuOpen,
          ]
        )}`}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default MenuIcon;
